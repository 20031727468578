import React from 'react';

import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Link } from 'react-router-dom';

import styles from './OrderExpand.module.less';

import { OrderStateIndicator } from 'components';
import { colors } from 'constants/ui';
import { IBulkOrder } from 'types/order';

const useStyles = makeStyles({
  container: {
    padding: '16px 24px 24px 60px',
    borderTop: '1px solid',
    borderColor: colors.LIGHT_GREY,
  },
});

interface IOrderExpandProps {
  item: IBulkOrder;
}

export const OrderExpand: React.FC<IOrderExpandProps> = ({ item }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <TableContainer component={Paper} elevation={1}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">
                <span className={styles.textMedium}>Sales Order #</span>
              </TableCell>
              <TableCell align="left">
                <span className={styles.textMedium}>Delivery Address</span>
              </TableCell>
              <TableCell align="left">
                <span className={styles.textMedium}>Product</span>
              </TableCell>
              <TableCell align="right">
                <span className={styles.textMedium}>Contract Term</span>
              </TableCell>
              <TableCell align="left">
                <span className={styles.textMedium}>Quantity</span>
              </TableCell>
              <TableCell align="right">
                <span className={styles.textMedium}>Total</span>
              </TableCell>
              <TableCell align="left">
                <span className={styles.textMedium}>Delivery Status</span>
              </TableCell>
              <TableCell />
              <TableCell align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {item.sale_orders.map((soRow) =>
              soRow.products.map((soProduct) => (
                <TableRow key={soRow.id}>
                  <TableCell align="left" component="th" scope="row">
                    <span className={styles.text}>{soRow.name}</span>
                  </TableCell>
                  <TableCell align="left" component="th" scope="row">
                    <span className={styles.text}>{soRow.delivery_address}</span>
                  </TableCell>
                  <TableCell align="left">
                    <span className={styles.text}>{soProduct.product.name}</span>
                  </TableCell>
                  <TableCell align="right">
                    <span className={styles.text}>
                      {soProduct.product.duration_term ? `${soProduct.product.duration_term} month(s)` : '─'}
                    </span>
                  </TableCell>
                  <TableCell align="left">
                    <span className={styles.text}>{soProduct.product.quantity}</span>
                  </TableCell>
                  <TableCell align="right">
                    <span className={styles.text}>
                      {soProduct.product.price_total !== 0 ? `$ ${soProduct.product.price_total}` : '─'}
                    </span>
                  </TableCell>
                  <TableCell align="left">
                    <span className={styles.text}>
                      {soProduct.product.delivery_status && soProduct.product.delivery_status !== 'n_a' ? (
                        <OrderStateIndicator state={soProduct.product.delivery_status} />
                      ) : (
                        ''
                      )}
                    </span>
                  </TableCell>
                  <TableCell>
                    {soProduct.product.delivery_status !== 'n_a' && (
                      <Button
                        component={Link}
                        to={`/order/details/${soProduct.product.id}`}
                        endIcon={<ArrowForwardIcon />}
                      >
                        View Details
                      </Button>
                    )}
                  </TableCell>
                  <TableCell>
                    <Button
                      size="small"
                      color="primary"
                      href={soRow.share_url}
                      target="_blank"
                      endIcon={<ArrowForwardIcon />}
                    >
                      {soRow.is_signed ? 'View details' : 'View quote'}
                    </Button>
                  </TableCell>
                </TableRow>
              )),
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
