/* eslint-disable react/display-name */

import React, { useEffect, useState } from 'react';

import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';

import { Table } from 'components';
import { OrderExpand, OrderFilters } from 'components/orders';
import OrderRowActions from 'components/orders/OrderRowActions/OrderRowActions';
import { TABLE_COLUMNS_ORDER } from 'constants/order_columns';
import { NavigationService } from 'services/navigationService';
import { useAppDispatch } from 'store';
import { getOrders } from 'store/actions/order';
import { breadcrumbSlice } from 'store/reducers/breadcrumb/breadcrumb';
import { orderSlice } from 'store/reducers/order/order';
import { useAppSelector } from 'store/store';
import { IBulkOrder } from 'types/order';

const useStyles = makeStyles({
  pageContainer: {
    padding: '88px 66px 64px',
  },
  headContainer: {
    marginBottom: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

export const OrdersPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { loading, data, filters, pagination, sort } = useAppSelector((state) => state.order);
  const { user } = useAppSelector((state) => state.auth);
  const [opened, setOpened] = useState<Set<number>>(new Set());

  const updatePagination = (paginationData: { rowsPerPage?: number; page?: number }) => {
    dispatch(orderSlice.actions.setPagination({ ...pagination, ...paginationData }));
  };

  useEffect(() => {
    dispatch(breadcrumbSlice.actions.setBreadcrumbs([{ title: 'Orders', url: '/orders' }]));
    if (!loading) {
      dispatch(getOrders({ filters, pagination, sort }));
    }
  }, [dispatch, filters, pagination, sort]);

  return (
    <div className={classes.pageContainer}>
      <Box className={classes.headContainer}>
        <Typography variant="h1">Orders</Typography>
        {user && user.authorized && (
          <Button
            onClick={() => NavigationService.navigateToShopByProduct()}
            style={{ marginLeft: 'auto' }}
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
          >
            New order
          </Button>
        )}
      </Box>
      <Table
        name="orders"
        loading={loading}
        columns={TABLE_COLUMNS_ORDER}
        data={data.bulk_orders}
        FilterComponent={OrderFilters}
        renderExpand={(item: IBulkOrder) => <OrderExpand item={item} />}
        renderActions={(item: IBulkOrder) => <OrderRowActions item={item} />}
        openItemIds={opened}
        openItemUpdate={setOpened}
        updatePagination={updatePagination}
        pagination={pagination}
        sort={sort}
        updateSort={(column: string) => dispatch(orderSlice.actions.updateSort(column))}
        count={(data && data.count) || 0}
      />
    </div>
  );
};
