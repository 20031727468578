import React, { FC, useCallback, useRef, useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FORM_ERROR } from 'final-form';
import { Field, Form } from 'react-final-form';
import SignaturePad from 'react-signature-canvas';
import { withWhiteLabelContext } from 'react-whitelabel';

import { TextFieldInput as TextField } from '../../common/uncontrolledComponents/TextField';
import styles from '../../orders/OrderRowActions/OrderRowActions.module.less';
import { SubmitDialog } from '../SubmitDialog/SubmitDialog';

import { generateSync } from './textToImage';

import { Spinner } from 'components/index';
import { colors } from 'constants/ui';
import { trimFormatter } from 'services/formatters';
import { useAppDispatch } from 'store';
import { acceptAndSign, getOrders } from 'store/actions/order';
import { useAppSelector } from 'store/store';
import { IAcceptAndSignPostData } from 'types/order';
import { composeValidators, requiredValidator } from 'validators';
import { TEXT_FIELD_HUGE_MAX_LENGTH } from 'validators/constants';

import './AcceptAndSignDialog.css';

interface IAcceptAndSign {
  id: string;
  price_total: number;
  customer: string;
  name: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  label: any;
}

interface IAcceptAndSignDialogFields {
  id: string;
  name: string;
}

const useStyles = makeStyles({
  dialogTitle: {
    fontSize: '18px',
    lineHeight: '28px',
    fontWeight: 700,
  },
  dialogData: {
    fontSize: '16px',
    lineHeight: '28px',
    fontWeight: 500,
    margin: '0 6px 16px 0',
  },
  dialogDataValues: {
    fontSize: '16px',
    lineHeight: '28px',
    fontWeight: 700,
  },
  dialogContent: {
    width: '488px',
  },
  submitButton: {
    marginLeft: '16px',
  },
  submitError: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 500,
    color: colors.RED,
    textAlign: 'center',
  },
  signClearButton: {
    float: 'left',
  },
});

const AcceptAndSignDialog: FC<IAcceptAndSign> = ({
  id,
  price_total,
  customer,
  open,
  setOpen,
  name,
  label,
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const signature = useRef({});
  const { filters, pagination, sort } = useAppSelector((state) => state.order);
  const { user } = useAppSelector((state) => state.auth);
  const [openedSubmitDialog, setOpenedSubmitDialog] = React.useState(false);
  const [openedSubmittedDialog, setOpenedSubmittedDialog] = React.useState(false);

  const [postData, setPostData] = useState<IAcceptAndSignPostData | null>(null);
  const { helpDialogLogo } = label;

  const dialogFields: IAcceptAndSignDialogFields[] = [
    { id: 'service_contact', name: 'Service Contact' },
    { id: 'contact_phone', name: 'Contact Phone' },
    { id: 'contact_email', name: 'Contact Email' },
    { id: 'company_billing_address', name: 'Company Billing Address' },
    { id: 'full_name', name: 'Full Name' },
    { id: 'title', name: 'Title' },
  ];

  function generateSignature() {
    // @ts-ignore
    const fullName = document.getElementsByName('full_name')[0].value;
    const signData = generateSync(fullName, {
      textAlign: 'center',
      maxWidth: 400,
      fontFamily: 'PinyonScript',
      fontSize: 56,
      verticalAlign: 'center',
      lineHeight: 56,
      fontWeight: 'bold',
    });
    // @ts-ignore
    signature.current.fromDataURL(signData);
  }

  function clearSignaturePad() {
    // @ts-ignore
    signature.current.clear();
  }

  function getSignature() {
    // @ts-ignore
    return signature.current.getTrimmedCanvas().toDataURL('image/png');
  }

  const handleFormSubmit = useCallback(
    async (formValues) => {
      // @ts-ignore
      if (signature.current.isEmpty()) return { [FORM_ERROR]: 'Fill sign' };
      const data: IAcceptAndSignPostData = {
        ...formValues,
        bulk_order_id: id,
        signature: getSignature(),
      };
      setPostData(data);
      setOpenedSubmitDialog(true);
      return null;
    },
    [id],
  );

  const [submitting, setSubmitting] = useState(false);

  const confirmSubmitOrder = useCallback(async () => {
    setSubmitting(true);
    await dispatch(acceptAndSign({ id, acceptAndSignData: postData }));
    setOpenedSubmittedDialog(true);
    setSubmitting(false);
    setOpen(false);
    dispatch(getOrders({ filters, pagination, sort }));
  }, [dispatch, filters, pagination, sort, id, postData, setOpen]);

  return (
    <>
      {openedSubmittedDialog && (
        <SubmitDialog visible={openedSubmittedDialog} setOpenParent={setOpenedSubmitDialog} id={id} />
      )}
      <Dialog
        open={openedSubmitDialog}
        onClose={() => setOpenedSubmitDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <img src={helpDialogLogo} alt="" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography>
              <span className={styles.largeTextBold}>Submit order?</span>
            </Typography>
            <Typography style={{ width: '432px' }}>
              <span className={styles.text}>
                Order {name} will be sent to our Provisioning Team for further processing.
              </span>
            </Typography>
          </DialogContentText>
        </DialogContent>
        {submitting && (
          <Box sx={{ display: 'flex', justifyContent: 'center', height: '60px' }}>
            <Spinner size={32} />
          </Box>
        )}
        {!submitting && (
          <DialogActions>
            <Button onClick={() => setOpenedSubmitDialog(false)} color="primary" autoFocus>
              Cancel
            </Button>
            <Button variant="contained" onClick={() => confirmSubmitOrder()} color="primary">
              Submit order
            </Button>
          </DialogActions>
        )}
      </Dialog>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          <Typography classes={{ root: classes.dialogTitle }}>Validate Order</Typography>
        </DialogTitle>
        <DialogContent classes={{ root: classes.dialogContent }}>
          <DialogContentText>
            <Form initialValues={{ full_name: user?.first_name }} onSubmit={handleFormSubmit}>
              {({ handleSubmit, submitError }) => (
                <form onSubmit={handleSubmit}>
                  {dialogFields.map((field, index) => (
                    <>
                      {index === 4 && (
                        <>
                          <Typography classes={{ root: classes.dialogData }}>
                            By signing this service agreement, I agree to the following terms:
                          </Typography>
                          <Box display="flex">
                            <Typography classes={{ root: classes.dialogData }}>Accepted on the behalf of:</Typography>
                            <Typography classes={{ root: classes.dialogDataValues }}>{customer}</Typography>
                          </Box>
                          <Box display="flex">
                            <Typography classes={{ root: classes.dialogData }}>For an amount of:</Typography>
                            <Typography classes={{ root: classes.dialogDataValues }}>${price_total}</Typography>
                          </Box>
                        </>
                      )}
                      <Field
                        id={field.id}
                        key={field.id}
                        name={field.id}
                        label={field.name}
                        variant="outlined"
                        component={TextField}
                        maxLength={TEXT_FIELD_HUGE_MAX_LENGTH}
                        formatOnBlur
                        format={trimFormatter}
                        validate={composeValidators(requiredValidator(field.name))}
                      />
                    </>
                  ))}
                  <span style={{ visibility: 'hidden', fontFamily: 'PinyonScript' }} />
                  <SignaturePad ref={signature} penColor="black" canvasProps={{ className: 'sigCanvas' }} />
                  {submitError && <Typography classes={{ root: classes.submitError }}>{submitError}</Typography>}
                  <Box textAlign="right">
                    <Button onClick={() => clearSignaturePad()} classes={{ root: classes.signClearButton }}>
                      <u>Clear</u>
                    </Button>
                    <Button onClick={() => generateSignature()} classes={{ root: classes.signClearButton }}>
                      <u>Auto Sign</u>
                    </Button>
                    <Button onClick={() => setOpen(false)} color="primary" autoFocus>
                      <span>Cancel</span>
                    </Button>
                    <Button classes={{ root: classes.submitButton }} type="submit" variant="contained" color="primary">
                      <span>Submit</span>
                    </Button>
                  </Box>
                </form>
              )}
            </Form>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default withWhiteLabelContext(AcceptAndSignDialog);
