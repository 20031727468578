import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { FILTER_STATE_DEFAULT } from 'constants/order';
import { defaultRowsPerPage } from 'constants/table';
import { getAcceptAndSignData, getOrderDetails, getOrderDetailsComments, getOrders } from 'store/actions/order';
import {
  IAcceptAndSignData,
  ICommentsResponse,
  INewOrder,
  IOrderFilters,
  IOrderResponse,
  ISaleOrderLine,
  OrderStateType,
} from 'types/order';
import { IPagination, SortDirection } from 'types/table';

export const initialState: OrderStateType = {
  data: { bulk_orders: [], count: 0 },
  filters: { state: FILTER_STATE_DEFAULT, company_id: 0 },
  pagination: { rowsPerPage: defaultRowsPerPage, page: 0 },
  sort: { sort_column: 'write_date', sort_direction: 'DESC' },
  loading: false,
  newOrder: null,
  selectedOrder: null,
  selectedOrderComments: null,
  commentsLoading: false,
  commentsPage: 0,
  acceptAndSignData: null,
  acceptAndSignDataLoading: false,
  error: null,
};

export const orderSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(getOrders.fulfilled, (state: OrderStateType, action: PayloadAction<IOrderResponse>) => ({
      ...state,
      data: action.payload,
      loading: false,
      error: false,
    }));
    builder.addCase(getOrders.pending, (state: OrderStateType) => ({
      ...state,
      loading: true,
      error: false,
    }));
    builder.addCase(getOrders.rejected, (state: OrderStateType) => ({
      ...state,
      data: { bulk_orders: [], count: 0 },
      loading: false,
      error: true,
    }));
    builder.addCase(getOrderDetails.fulfilled, (state: OrderStateType, action: PayloadAction<ISaleOrderLine>) => ({
      ...state,
      selectedOrder: action.payload,
      loading: false,
    }));
    builder.addCase(getOrderDetails.pending, (state: OrderStateType) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(getOrderDetails.rejected, (state: OrderStateType) => ({
      ...state,
      selectedOrder: null,
      loading: false,
    }));
    builder.addCase(
      getOrderDetailsComments.fulfilled,
      (state: OrderStateType, action: PayloadAction<ICommentsResponse>) => ({
        ...state,
        selectedOrderComments: action.payload,
        commentsLoading: false,
      }),
    );
    builder.addCase(getOrderDetailsComments.pending, (state: OrderStateType) => ({
      ...state,
      commentsLoading: true,
    }));
    builder.addCase(getOrderDetailsComments.rejected, (state: OrderStateType) => ({
      ...state,
      selectedOrderComments: null,
      commentsLoading: true,
    }));
    builder.addCase(getAcceptAndSignData.pending, (state: OrderStateType) => ({
      ...state,
      acceptAndSignDataLoading: true,
      acceptAndSignData: null,
    }));
    builder.addCase(getAcceptAndSignData.rejected, (state: OrderStateType) => ({
      ...state,
      acceptAndSignDataLoading: false,
      acceptAndSignData: null,
    }));
    builder.addCase(
      getAcceptAndSignData.fulfilled,
      (state: OrderStateType, action: PayloadAction<IAcceptAndSignData>) => ({
        ...state,
        acceptAndSignDataLoading: false,
        acceptAndSignData: action.payload,
      }),
    );
  },
  initialState,
  name: '@order',
  reducers: {
    setFilters: (state: OrderStateType, action: PayloadAction<IOrderFilters>) => ({
      ...state,
      filters: action.payload,
    }),
    resetFilters: (state: OrderStateType) => ({ ...state, filters: initialState.filters }),
    setPagination: (state: OrderStateType, action: PayloadAction<IPagination>) => ({
      ...state,
      pagination: action.payload,
    }),
    setCommentsPage: (state: OrderStateType, action: PayloadAction<number>) => ({
      ...state,
      commentsPage: action.payload,
    }),
    addNewOrder: (state: OrderStateType, action: PayloadAction<INewOrder>) => {
      return { ...state, newOrder: action.payload };
    },
    updateSort: (state: OrderStateType, action: PayloadAction<string>) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const sort_direction = (
        state.sort.sort_column === action.payload && state.sort.sort_direction === 'ASC' ? 'DESC' : 'ASC'
      ) as SortDirection;
      return { ...state, sort: { sort_column: action.payload, sort_direction } };
    },
  },
});
