import { createAsyncThunk } from '@reduxjs/toolkit';

import { ticketApi } from 'api/ticketApi';
import { IPagination, ISort } from 'types/table';
import { IAttachFile, INewTicket, TicketFilters } from 'types/ticket';

type TicketApiParams = {
  filters: TicketFilters;
  pagination: IPagination;
  sort: ISort;
};

export const getTickets = createAsyncThunk(
  '@ticket/getTickets',
  async ({ filters, pagination, sort }: TicketApiParams) => {
    const { data } = await ticketApi.getTickets(filters, pagination, sort);
    return data;
  },
);

export const getTicketDetails = createAsyncThunk('@ticket/getTicketDetails', async (id: string) => {
  const { data } = await ticketApi.getTicketDetails(id);
  return data;
});

export const setTicketAsClosed = createAsyncThunk('@ticket/setTicketAsClosed', async (id: string) => {
  const { data } = await ticketApi.setTicketAsClosed(id);
  return data;
});

interface ICreateTicketAction {
  newTicket: INewTicket;
  attachments: IAttachFile[];
}

async function uploadAttach(attach: IAttachFile) {
  const { data } = await ticketApi.fetchUploadUrl(attach.file.name, attach.file.size);
  return { file_id: data.file_id, file_name: attach.file.name };
}

export async function uploadAttachmentsArray(num: number, attachments: IAttachFile[]) {
  await uploadAttach(attachments[num]);
}

export const createTicket = createAsyncThunk(
  '@ticket/createTicket',
  async ({ newTicket, attachments }: ICreateTicketAction) => {
    const resp = await ticketApi.createTicket(newTicket);
    const ticketData = resp.data;

    attachments.forEach((attachment) => {
      const formData = new FormData();
      formData.append('files', attachment.file);
      ticketApi.uploadAttachment(ticketData.id, formData);
    });

    return ticketData;
  },
);
