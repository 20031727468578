import React, { FC, useEffect, useMemo } from 'react';

import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Paper,
  Step,
  StepIconProps,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DateRangeIcon from '@material-ui/icons/DateRange';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { useParams } from 'react-router-dom';
import { withWhiteLabelContext } from 'react-whitelabel';

import styles from './OrderDetailsPage.module.less';

import { BreadCrumbs, GridC, OrderDetailsComments, FullScreenLoader } from 'components';
import { PopupForm } from 'components/common/PopupForm/PopupForm';
import { StateIndicator } from 'components/common/StateIndicator/StateIndicator';
import { colors } from 'constants/ui';
import { useAppDispatch } from 'store';
import { getOrderDetails } from 'store/actions/order';
import { breadcrumbSlice } from 'store/reducers/breadcrumb/breadcrumb';
import { useAppSelector } from 'store/store';

const initSteps = ['Confirmed', 'In process', 'Sent to Dispatch', 'Done'];
const initStates = ['confirmed', 'progress', 'dispatch', 'done'];

const OrderDetailPage: FC<{ label: any }> = (props): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();

  const { label } = props;
  const { name } = label;

  const { loading, selectedOrder } = useAppSelector((state) => state.order);

  const [surveyOpened, setSurveyOpened] = React.useState(false);
  const [steps, setSteps] = React.useState(initSteps);
  const [states, setStates] = React.useState(initSteps);
  const [activeStep, setActiveStep] = React.useState(0);

  useEffect(() => {
    dispatch(getOrderDetails(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (selectedOrder) {
      const newSteps = [...initSteps];
      const newStates = [...initStates];
      if (selectedOrder.delivery_status === 'cancel') {
        newSteps[1] = 'Cancelled';
        newStates[1] = 'cancel';
      }
      setSteps(newSteps);
      setStates(newStates);
    }
  }, [selectedOrder]);

  useEffect(() => {
    if (selectedOrder) {
      setActiveStep(states.indexOf(selectedOrder.delivery_status));
    }
  }, [steps, states, selectedOrder]);

  useEffect(() => {
    if (selectedOrder) {
      dispatch(
        breadcrumbSlice.actions.addBreadcrumb({
          title: `${selectedOrder.service_delivery_name}`,
          url: `/order/details/${id}`,
        }),
      );
    }
  }, [dispatch, id, selectedOrder]);

  const StepIndicator = useMemo(() => {
    return ({ active }: StepIconProps) => (
      <StateIndicator state={active ? states[activeStep] : ''} titleVisible={false} />
    );
  }, [states, activeStep]);

  if (loading || !selectedOrder) return <FullScreenLoader />;

  return (
    <>
      <BreadCrumbs />
      <div className={styles.pageContainer}>
        <GridC container>
          <Grid item xs={6}>
            <Paper style={{ padding: '24px', height: '100%' }}>
              <Box marginLeft="12px" marginRight="12px" marginTop="21px" display="flex">
                <LocalShippingIcon color="primary" style={{ marginRight: '15px' }} />
                <span className={styles.mediumTextBold}>Delivery Progress</span>
              </Box>
              <Stepper
                style={{ height: 'calc(100% - 105px)', display: 'flex', padding: '0', alignItems: 'center' }}
                alternativeLabel
                activeStep={activeStep}
              >
                {steps.map((slabel) => (
                  <Step key={slabel}>
                    <StepLabel StepIconComponent={StepIndicator}>
                      <span className={styles.textMedium}>{slabel}</span>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
              {selectedOrder && selectedOrder.survey.length > 0 && selectedOrder.survey[0].mrp_answer_state !== 'done' && (
                <Box
                  style={{
                    padding: '14px 0px 15px 17px',
                    background: colors.ORANGE_LIGHT,
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <ReportProblemOutlinedIcon style={{ marginRight: '13px', color: colors.ORANGE }} />
                  <span className={styles.text}>
                    In order to proceed please fill out and update{' '}
                    <span style={{ color: colors.ORANGE_TEXT }}>Arrangements Details</span>
                  </span>
                </Box>
              )}
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper style={{ padding: '24px' }}>
              <Box marginLeft="12px" marginRight="12px" marginTop="21px" display="flex">
                <AssignmentIcon color="primary" style={{ marginRight: '15px' }} />
                <span className={styles.mediumTextBold}>Service Order Details</span>
              </Box>
              <TableContainer style={{ paddingLeft: '12px', paddingTop: '18px', paddingRight: '12px' }}>
                <Table>
                  <TableHead style={{ background: colors.WHITE_GREY }}>
                    <TableCell>
                      <span className={styles.tinyText}>Service Order#</span>
                    </TableCell>
                    <TableCell>
                      <span className={styles.tinyText}>{selectedOrder.service_delivery_name}</span>
                    </TableCell>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <span className={styles.tinyText}>Sales Order#</span>
                      </TableCell>
                      <TableCell>
                        <span className={styles.tinyText}>{selectedOrder.order_name}</span>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <span className={styles.tinyText}>Delivery Address</span>
                      </TableCell>
                      <TableCell>
                        <span className={styles.tinyText}>{selectedOrder.delivery_address}</span>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <span className={styles.tinyText}>Product</span>
                      </TableCell>
                      <TableCell>
                        <span className={styles.tinyText}>{selectedOrder.name}</span>
                      </TableCell>
                    </TableRow>
                    {selectedOrder.duration_term > 0 && (
                      <TableRow>
                        <TableCell>
                          <span className={styles.tinyText}>Contract Term</span>
                        </TableCell>
                        <TableCell>
                          <span className={styles.tinyText}>{selectedOrder.duration_term} month(s)</span>
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell>
                        <span className={styles.tinyText}>Quantity</span>
                      </TableCell>
                      <TableCell>
                        <span className={styles.tinyText}>{selectedOrder.quantity}</span>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <span className={styles.tinyText}>Sum</span>
                      </TableCell>
                      <TableCell>
                        <span className={styles.tinyText}>{selectedOrder.price_total}</span>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Card style={{ height: '100%' }}>
              <CardContent style={{ padding: '25px' }}>
                <Box display="flex">
                  <PermContactCalendarIcon color="primary" style={{ marginRight: '15px' }} />
                  <span className={styles.mediumTextBold}>{name}</span>
                </Box>
                <Divider />
                {selectedOrder.sale_contact && (
                  <>
                    <Typography>
                      <span className={styles.tinyText}>{selectedOrder.sale_contact.name}</span>
                    </Typography>
                    <Typography>
                      <span className={styles.tinyText}>{selectedOrder.sale_contact.email}</span>
                    </Typography>
                    <Typography>
                      <span className={styles.tinyText}>{selectedOrder.sale_contact.phone}</span>
                    </Typography>
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card style={{ height: '100%' }}>
              <CardContent style={{ padding: '25px' }}>
                <Box display="flex">
                  <PermContactCalendarIcon color="primary" style={{ marginRight: '15px' }} />
                  <span className={styles.mediumTextBold}>{selectedOrder.customer_name}</span>
                </Box>
                <Divider />
                {selectedOrder.customer_contact && (
                  <>
                    <Typography>
                      <span className={styles.tinyText}>{selectedOrder.customer_contact.name}</span>
                    </Typography>
                    <Typography>
                      <span className={styles.tinyText}>{selectedOrder.customer_contact.email}</span>
                    </Typography>
                    <Typography>
                      <span className={styles.tinyText}>{selectedOrder.customer_contact.phone}</span>
                    </Typography>
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card>
              <CardContent style={{ padding: '25px' }}>
                <Box display="flex">
                  <DateRangeIcon color="primary" style={{ marginRight: '15px' }} />
                  <span className={styles.mediumTextBold}>Arrangements</span>
                  {selectedOrder.survey.length > 0 && (
                    <>
                      <PopupForm
                        url={selectedOrder.survey[0].survey_start_url}
                        opened={surveyOpened}
                        setOpened={setSurveyOpened}
                        actionAfterClosing={() => {
                          dispatch(getOrderDetails(id));
                        }}
                      />
                      <Button
                        style={{ padding: 0, marginLeft: 'auto' }}
                        variant="text"
                        color="primary"
                        endIcon={<ArrowForwardIcon />}
                        onClick={() => setSurveyOpened(true)}
                      >
                        <span className={styles.smallText}>
                          {selectedOrder.survey[0].mrp_answer_state === 'done' ? 'View details' : 'Provide details'}
                        </span>
                      </Button>
                    </>
                  )}
                </Box>
                <Divider />
                <Typography>
                  <span className={styles.tinyText}>Planned Date: {selectedOrder.planned_delivery_date}</span>
                </Typography>
                <Typography>
                  <span className={styles.tinyText}>Access Hours: {selectedOrder.access_time}</span>
                </Typography>
                <Typography>
                  <span className={styles.tinyText}>FOC Date: {selectedOrder.foc_date}</span>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <OrderDetailsComments id={id} />
        </GridC>
      </div>
    </>
  );
};

export default withWhiteLabelContext(OrderDetailPage);
