import React from 'react';

import { Box, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { colors } from 'constants/ui';
import { useAppSelector } from 'store/store';

const useStyles = makeStyles({
  balanceWrapper: {
    display: 'inline-flex',
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '36px',
  },
  valueHead: {
    fontWeight: 600,
    fontSize: '10px',
    lineHeight: '16px',
    color: colors.MIDDLE_GREY,
    textTransform: 'uppercase',
    letterSpacing: '1px',
  },
  valuePaper: {
    marginLeft: '8px',
  },
  totalBlock: {
    margin: '18px 16px 14px',
  },
  currentBlock: {
    marginLeft: '8px',
    margin: '18px auto 14px',
    textAlign: 'center',
    color: colors.PURPLE_HEART,
    width: '160px',
  },
  overdueContainer: {
    marginLeft: '8px',
    padding: '8px 8px 8px 43px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: colors.RED,
    width: '455px',
  },
  overdueDetailsWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    paddingTop: '10px',
    alignItems: 'flex-start',
    height: '100%',
    width: '287px',
    color: colors.RED,
  },
  overdueAmount: {
    marginTop: '6px',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
  },
  overdueMidHead: {
    fontWeight: 500,
    fontSize: '11px',
    lineHeight: '20px',
    color: colors.MIDDLE_GREY,
    textTransform: 'uppercase',
    letterSpacing: '1px',
  },
  overdueMidAmount: {
    marginTop: '6px',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
    color: colors.DARK_40,
  },
});

export const BillBalance: React.FC = () => {
  const classes = useStyles();
  const { billData } = useAppSelector((state) => state.bill);
  return (
    <div className={classes.balanceWrapper}>
      <Paper>
        <Box textAlign="center" className={classes.totalBlock}>
          <Typography className={classes.valueHead}>Total outstanding balance</Typography>
          <div>${billData ? billData.total_amount : 0}</div>
        </Box>
      </Paper>
      <Paper className={classes.valuePaper}>
        <Box textAlign="center" className={classes.currentBlock}>
          <Typography className={classes.valueHead}>current due</Typography>
          <div>${billData ? billData.current_due_amount : 0}</div>
        </Box>
      </Paper>
      <Paper className={classes.overdueContainer}>
        <Box textAlign="center">
          <Typography className={classes.valueHead}>Overdue</Typography>
          <div>${billData ? billData.overdue_amount : 0}</div>
        </Box>
        <Paper variant="outlined" className={classes.overdueDetailsWrapper}>
          <Box textAlign="center">
            <Typography className={classes.valueHead}>60+ days</Typography>
            <div className={classes.overdueAmount}>${billData ? billData.overdue_rds_sixty_plus_days : 0}</div>
          </Box>
          <Box textAlign="center">
            <Typography className={classes.overdueMidHead}>31-60 days</Typography>
            <div className={classes.overdueMidAmount}>${billData ? billData.overdue_rds_between_sixty_thirty : 0}</div>
          </Box>
          <Box textAlign="center">
            <Typography className={classes.valueHead}>1-30 days</Typography>
            <div className={classes.overdueAmount}>${billData ? billData.overdue_rds_before_thirty : 0}</div>
          </Box>
        </Paper>
      </Paper>
    </div>
  );
};
