import { createAsyncThunk } from '@reduxjs/toolkit';

import { orderApi } from 'api/orderApi';
import { uploadAttachmentsArray } from 'store/actions/ticket/ticket';
import { IOrderFilters, SignOrderDataType } from 'types/order';
import { IPagination, ISort } from 'types/table';
import { IAttachFile, IAttachInfo } from 'types/ticket';

type OrderApiParams = {
  filters: IOrderFilters;
  pagination: IPagination;
  sort: ISort;
};

type CommentsApiParams = {
  id: string;
  page: number;
};

type NewCommentApiData = {
  order_id: string;
  text: string;
  attachments: IAttachFile[];
};

export const getOrders = createAsyncThunk('@order/getOrders', async ({ filters, pagination, sort }: OrderApiParams) => {
  const { data } = await orderApi.getOrders(filters, pagination, sort);
  return data;
});

export const getAcceptAndSignData = createAsyncThunk('@order/getAcceptAndSignData', async (id: string) => {
  const { data } = await orderApi.getAcceptAndSignData(id);
  return data;
});

export const acceptAndSign = createAsyncThunk('@order/acceptAndSign', async (postData: SignOrderDataType) => {
  const { data } = await orderApi.acceptAndSign(postData.id, postData.acceptAndSignData);
  return data;
});

export const cancelOrder = createAsyncThunk('@order/cancelOrder', async (id: string) => {
  const { data } = await orderApi.orderCancel(id);
  return data;
});

export const getOrderDetails = createAsyncThunk('@order/getOrderDetails', async (id: string) => {
  const { data } = await orderApi.getOrderDetails(id);
  return data;
});

export const getOrderDetailsComments = createAsyncThunk(
  '@order/getOrderDetailsComments',
  async ({ id, page }: CommentsApiParams) => {
    const { data } = await orderApi.getOrderDetailsComments(id, page);
    return data;
  },
);

export const sendComment = createAsyncThunk(
  '@order/sendComment',
  async ({ order_id, text, attachments }: NewCommentApiData) => {
    const uploadedAttachments: IAttachInfo[] = [];
    // Sequential file uploading
    if (attachments.length > 0) {
      await uploadAttachmentsArray(0, attachments);
    }
    const { data } = await orderApi.sendComment({ order_id, text, attachments: uploadedAttachments });
    return data;
  },
);
