import React, { useCallback } from 'react';

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FORM_ERROR } from 'final-form';
import { Field, Form } from 'react-final-form';

import { useAppSelector } from '../../../store/store';
import { TextAreaField } from '../../common/uncontrolledComponents/TextAreaField';
import { TextFieldInput as TextField } from '../../common/uncontrolledComponents/TextField';
import { TimePickerInput as TimePicker } from '../../common/uncontrolledComponents/TimePickerField';

import styles from './NewTicketDialog.module.less';

import logoOrderSubmitted from 'assets/svg/submitted_order.svg';
import logoOrderCancel from 'assets/svg/warning_dialog_title.svg';
import { AttachUpload } from 'components/index';
import { colors } from 'constants/ui';
import { trimFormatter } from 'services/formatters';
import { useAppDispatch } from 'store';
import { createTicket } from 'store/actions/ticket';
import { IAttachFile, INewTicket } from 'types/ticket';
import { getTableDate } from 'utils/utils';
import { composeValidators, requiredValidator } from 'validators';
import { TEXT_FIELD_HUGE_MAX_LENGTH } from 'validators/constants';

const useStyles = makeStyles({
  confirmCancelButton: {
    background: colors.RED,
    color: colors.WHITE,
  },
  submitError: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 500,
    color: colors.RED,
    textAlign: 'center',
  },
  attachmentsLabel: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 500,
    marginBottom: '12px',
  },
});

export const NewTicketDialog: React.FC<{
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ open, setOpen }): JSX.Element => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const [createLoading, setCreateLoading] = React.useState(false);

  const [ticketType, setTicketType] = React.useState<number | null>(1);
  const [company, setCompany] = React.useState<number | null>(null);
  const [submitted, setSubmitted] = React.useState(false);
  const [cancelled, setCancelled] = React.useState(false);
  const [intrusive, setIntrusive] = React.useState(false);
  const [attachments, setAttachments] = React.useState<IAttachFile[]>([]);
  const { user } = useAppSelector((state) => state.auth);

  const handleChangeIntrusive = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIntrusive(event.target.checked);
  };

  const handleChangeTicketType = (event: React.ChangeEvent<{ value: unknown }>) => {
    setTicketType(event.target.value as number);
  };

  const handleChangeCompany = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCompany(event.target.value as number);
  };

  const handleFormSubmit = useCallback(
    async (formValues) => {
      const errors: string[] = [];
      const dateFromValue = formValues.date_from;
      const dateToValue = formValues.date_to;
      if (
        intrusive &&
        (dateFromValue.getHours() > dateToValue.getHours() ||
          (dateFromValue.getHours() === dateToValue.getHours() &&
            dateFromValue.getMinutes() >= dateToValue.getMinutes()))
      )
        errors.push('The start time must be less than the end time!');
      if (attachments.some((attach) => !attach.isValid)) {
        errors.push('Remove invalid files');
      }
      if (errors.length === 0) {
        const ticketData: INewTicket = {
          name: formValues.subject,
          description: formValues.description,
          ticket_type_id: ticketType,
          company_id: company,
          intrusive_testing: intrusive ? 'yes' : 'no',
        };
        if (intrusive) {
          ticketData.time_from = getTableDate(formValues.date_from);
          ticketData.time_to = getTableDate(formValues.date_to);
        }
        setCreateLoading(true);
        await dispatch(createTicket({ newTicket: ticketData, attachments })).then(() => {
          setCreateLoading(false);
          setSubmitted(true);
        });
      }
      return errors.length > 0 ? { [FORM_ERROR]: errors.join('\n') } : null;
    },
    [dispatch, attachments, ticketType, company, intrusive],
  );

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle style={{ paddingBottom: '0' }}>
        {cancelled && <img src={logoOrderCancel} alt="" />}
        {submitted && <img src={logoOrderSubmitted} alt="" />}
        <p className={styles.largeTextBold}>
          {!cancelled && !submitted && 'Create ticket'}
          {submitted && 'Ticket request has been successfully submitted'}
          {cancelled && 'Cancel ticket creation?'}
        </p>
      </DialogTitle>
      <DialogContent style={{ width: '432px', paddingTop: '0' }}>
        <DialogContentText>
          <Box display={!cancelled && !submitted ? '' : 'none'}>
            <Form onSubmit={handleFormSubmit}>
              {({ handleSubmit, submitError }) => (
                <form className={styles.newTicketForm} onSubmit={handleSubmit}>
                  <Field
                    id="subject"
                    name="subject"
                    label="Subject"
                    variant="outlined"
                    component={TextField}
                    maxLength={TEXT_FIELD_HUGE_MAX_LENGTH}
                    formatOnBlur
                    format={trimFormatter}
                    validate={composeValidators(requiredValidator('Subject'))}
                  />
                  <FormControl variant="outlined">
                    <InputLabel>Company</InputLabel>
                    <Select label="Choose a company" value={company} onChange={handleChangeCompany}>
                      {user.companies.map((cmpn) => (
                        <MenuItem key={cmpn[0]} value={cmpn[0]}>
                          <span className={styles.text}>{cmpn[1]}</span>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Field
                    id="description"
                    name="description"
                    label="Description"
                    variant="outlined"
                    component={TextAreaField}
                    formatOnBlur
                    format={trimFormatter}
                    validate={composeValidators(requiredValidator('Description'))}
                    helper
                  />
                  <FormControl variant="outlined">
                    <InputLabel>Ticket type</InputLabel>
                    <Select label="Ticket type" value={ticketType} onChange={handleChangeTicketType}>
                      {user.helpdesk_types.map((type) => (
                        <MenuItem key={type[0]} value={type[0]}>
                          <span className={styles.text}>{type[1]}</span>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <InputLabel className={classes.attachmentsLabel}>Attachments</InputLabel>
                  <AttachUpload attachments={attachments} setAttachments={setAttachments} />
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={intrusive}
                        onChange={handleChangeIntrusive}
                        placeholder="Intrusive testing allowed"
                      />
                    }
                    label={<span className={styles.text}>Intrusive testing allowed</span>}
                  />
                  {intrusive && (
                    <Box display="flex">
                      <Field
                        id="date_from"
                        name="date_from"
                        label="From"
                        placeholder="From"
                        component={TimePicker}
                        validate={composeValidators(requiredValidator('From time'))}
                      />
                      <Divider style={{ width: '10px' }} orientation="vertical" />
                      <Field
                        style={{ marginLeft: '5px' }}
                        id="date_to"
                        name="date_to"
                        label="To"
                        placeholder="To"
                        component={TimePicker}
                        validate={composeValidators(requiredValidator('To time'))}
                      />
                    </Box>
                  )}
                  {submitError && <Typography classes={{ root: classes.submitError }}>{submitError}</Typography>}
                  <Box marginTop="36px" textAlign="end">
                    <Button onClick={() => setCancelled(true)} color="primary" autoFocus>
                      <span className={styles.mediumText}>Cancel</span>
                    </Button>
                    <Button style={{ marginLeft: '16px' }} type="submit" variant="contained" color="primary">
                      <span className={styles.mediumText}>Submit</span>
                    </Button>
                  </Box>
                </form>
              )}
            </Form>
          </Box>
          {submitted && <span className={styles.text}>We will contact you by email shortly.</span>}
          {cancelled && <span className={styles.text}>The information you filled in will be lost.</span>}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {submitted && (
          <Button onClick={() => setOpen(false)} variant="contained" color="primary">
            Continue
          </Button>
        )}
        {cancelled && (
          <>
            <Button onClick={() => setCancelled(false)} color="primary" disabled={createLoading} autoFocus>
              No, keep it
            </Button>
            <Button
              onClick={() => setOpen(false)}
              className={classes.confirmCancelButton}
              color="primary"
              disabled={createLoading}
            >
              Yes, cancel
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};
