import React, { FC, ReactNode } from 'react';

import { AppBar, Box, Paper, Popper, Tab, Tabs, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Email, Phone } from '@material-ui/icons';
import { Link, useLocation } from 'react-router-dom';
import { withWhiteLabelContext } from 'react-whitelabel';

import { PopupForm } from '../PopupForm/PopupForm';
import Logo from '../WhilteLabelComponents/Logo/Logo';

import styles from './MainLayout.module.less';

import { MegaMenu, UserDetailsButton } from 'components/index';
import { ROUTER_PATHS } from 'constants/routerPaths';
import { colors } from 'constants/ui';
import { useAppSelector } from 'store/store';

const shopTab = { label: 'Shop by Product', navigateTo: ROUTER_PATHS.shopByProduct };

const navigationTabs = [
  { tabLabel: 'Orders', navigateTo: ROUTER_PATHS.orders },
  { tabLabel: 'Bills', navigateTo: ROUTER_PATHS.bills },
  { tabLabel: 'Support Tickets', navigateTo: ROUTER_PATHS.supportTickets },
];

const MainLayout: FC<{ children: ReactNode; label: any }> = ({ children, label }) => {
  const useStyles = makeStyles({
    toolbar: {
      padding: 0,
    },
    navTabRoot: {
      padding: 0,
      minWidth: 0,
      textTransform: 'none',
      marginRight: '40px',
      height: '80px',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '24px',
      color: colors.DARK,
      '&.Mui-selected': {
        fontWeight: 600,
        color: label.tabs.selectedColor,
      },
    },
    cartButton: {
      fontWeight: 600,
      marginRight: '16px',
    },
    megaMenuPopper: {
      paddingLeft: '120px',
      paddingRight: '120px',
      width: '100%',
    },
  });
  const classes = useStyles();
  const location = useLocation();
  const anchorRef = React.useRef<HTMLElement>(null);
  const [open, setOpen] = React.useState(false);
  const { portalData } = useAppSelector((state) => state.auth);
  const [termsOpened, setTermsOpened] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  let backgroundClass = styles.background_1;
  if (window.location.pathname === ROUTER_PATHS.shopByProduct) {
    backgroundClass = styles.background_2;
  }
  function getTabValue(path: string) {
    if (path.startsWith('/shop')) return shopTab.navigateTo;
    if (path.startsWith('/order')) return navigationTabs[0].navigateTo;
    if (path.startsWith('/bills')) return navigationTabs[1].navigateTo;
    if (path.startsWith('/support')) return navigationTabs[2].navigateTo;
    return false;
  }

  const { name, emailPostfix } = label;

  return (
    <div>
      <div>
        <header ref={anchorRef}>
          <AppBar className={styles.header} position="static" color="inherit" elevation={0}>
            <Toolbar className={classes.toolbar}>
              <Logo />
              <Tabs className={styles.headerNav} value={getTabValue(location.pathname)}>
                <Tab
                  classes={{ root: classes.navTabRoot }}
                  label={shopTab.label}
                  value={shopTab.navigateTo || ROUTER_PATHS.category}
                  textColor="inherit"
                  onClick={() => setOpen(true)}
                />
                {navigationTabs.map(({ tabLabel, navigateTo }) => (
                  <Tab
                    classes={{ root: classes.navTabRoot }}
                    value={navigateTo}
                    label={tabLabel}
                    key={navigateTo}
                    component={Link}
                    to={navigateTo}
                  />
                ))}
              </Tabs>
              <Box flexGrow={1} />
              <div className={styles.headerUserInfo}>
                <UserDetailsButton />
              </div>
            </Toolbar>
          </AppBar>
          <Box borderTop={1} borderColor={colors.LINES_PURPLE} />
          <Popper
            className={classes.megaMenuPopper}
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            placement="bottom"
          >
            <MegaMenu handleClose={handleClose} />
          </Popper>
        </header>
        <div className={`${styles.flexWrapper} ${backgroundClass}`}>
          <div className={styles.childContainer}>{children}</div>
          <footer>
            {portalData && <PopupForm url={portalData.privacy_link} opened={termsOpened} setOpened={setTermsOpened} />}
            <Box borderTop={1} borderColor={colors.LINES_PURPLE} />
            <Paper className={styles.footerContainer} elevation={0} square>
              <Box width={1 / 4}>
                <div className={styles.contactItem}>
                  <Phone className={styles.contactIcon} />
                  <span>(877) 333-0303</span>
                </div>
                <div className={styles.contactItem}>
                  <Email className={styles.contactIcon} />
                  <a href={`mailto:support@${emailPostfix}.com`}>Email us</a>
                </div>
              </Box>
              <Box width={1 / 4} display="flex" flexDirection="column" alignItems="center">
                <div className={styles.footerNav}>
                  <div className={styles.footerLink}>
                    <div
                      tabIndex={0}
                      role="button"
                      className={styles.footerLink}
                      onClick={() => setTermsOpened(true)}
                      onKeyDown={() => {}}
                    >
                      Terms of Services
                    </div>
                  </div>
                </div>
              </Box>
              <Box width={1 / 4} display="flex" flexDirection="column" alignItems="center">
                <div className={styles.footerNav}>
                  <div className={styles.footerLink}>
                    <div
                      tabIndex={0}
                      role="button"
                      className={styles.footerLink}
                      onClick={() => setTermsOpened(true)}
                      onKeyDown={() => {}}
                    >
                      Privacy Policy
                    </div>
                  </div>
                </div>
              </Box>
              <Box className={styles.copyright} width={1 / 4}>
                <div>{name} Communications © 1995 - 2021</div>
                <div>All Rights Reserved.</div>
              </Box>
            </Paper>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default withWhiteLabelContext(MainLayout);
